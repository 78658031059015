import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('menu.integrations'))+" ")])])],1),_c(VRow,{staticClass:"my-4 mx-1",attrs:{"justify":"start"}},_vm._l((4),function(n){return _c(VCol,{key:n,attrs:{"cols":"3"}},[_c(VCard,{staticClass:"pa-4 d-flex flex-column",style:(`border: 1px dashed #696969; opacity: ${1 - (n-1) * 0.267};`),attrs:{"elevation":"0","height":"300px"}},[_c(VCardText,{staticClass:"d-flex justify-center align-center flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t('general.ui.not_data_found'))+" ")]),_c(VCardActions,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"disabled":"","block":"","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.register'))+" ")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }