<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t('menu.integrations') }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-row justify="start" class="my-4 mx-1">
      <v-col cols="3" v-for="n in 4" :key="n">
        <v-card
          class="pa-4 d-flex flex-column"
        elevation="0"
        :style="`border: 1px dashed #696969; opacity: ${1 - (n-1) * 0.267};`"
        height="300px">
        <v-card-text class="d-flex justify-center align-center flex-grow-1">
          {{ $t('general.ui.not_data_found') }}
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn disabled block elevation="0">
            {{ $t('general.buttons.register') }}
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Integrations Dialogs -->
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import PermissionService from "@/services/PermisionService";
import CardWrapper from "@integrations/common/CardWrapper.vue";

export default {
  name: "IndexView",
  data() {
    return {}
  },
  methods: { }
}
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.card-integration {
  border-radius: 4px;
  transition: box-shadow .3s ease-in-out;
  color: #424242;
}

.card-integration:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom__chip {
  border-radius: .4em !important;
  margin: 0 10px;
}
</style>